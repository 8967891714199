/** @jsx jsx */
import { jsx } from "@emotion/core"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Disqus } from "gatsby-plugin-disqus"

import Layout from "../components/layout"
import Card from "../components/card"

export const query = graphql`
  query($slug: String!) {
    note: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      body
    }
  }
`

export default function Note({ data: { note } }) {
  let disqusConfig = {
    url: `https://google.com`,
    identifier: note.slug,
    title: note.title,
  }
  return (
    <Layout>
      <Card>
        <h1>{note.frontmatter.title}</h1>
        <MDXRenderer>{note.body}</MDXRenderer>
        <p>
          Thanks for reading! Have something to say? Please, leave a comment!
        </p>
        <Disqus config={disqusConfig} />
      </Card>
    </Layout>
  )
}
